<template>
	<div class="page" v-loading="loading">
		<gc-header headerData="directDetail"></gc-header>
		<div class="page-main directive-detail-main">
			<gc-group-detail :data="basicInfoData"></gc-group-detail>
			<div class="basic-info"></div>
			<div class="directive-detail-table-show">
				<div class="table-header">
					<span>指令执行表具监控</span>
					<el-button
						v-if="tenantType == 1 && $has('monitor:run:batch-command:resend')"
						v-click-blur
						type="primary"
						:disabled="!(queryParams.status == 2 && tableData.length > 0)"
						@click="handleReSend"
					>
						指令补发
					</el-button>
				</div>
				<div class="table-box">
					<div class="search-box" v-if="tenantType == 1">
						<div class="search-items">
							<gc-custom-search
								class="search"
								key-word="指令状态"
								type="select"
								:search-option="commandStatus"
								:search.sync="params['status']"
								:search-option-key="{ label: 'name', value: 'defaultValue' }"
								needAllForSearch
							></gc-custom-search>
						</div>
						<div class="search-btns">
							<el-button v-click-blur type="primary" class="query" @click="query">查 询</el-button>
							<el-button v-click-blur @click="reset">重 置</el-button>
						</div>
					</div>
					<gc-table
						class="table-content"
						:columns="columns"
						:table-data="tableData"
						:border="true"
						:showPage="true"
						pagination-type="special"
						:page-params="pageParams"
						@current-page-change="currentPageChange"
					></gc-table>
				</div>
			</div>
		</div>
		<command-detail :visible.sync="visible" :commandDetailData="commandDetailData"></command-detail>
	</div>
</template>

<script>
import { apiBatchCommandDetailList, apiGetBasicInfo, apiReSend } from '@/apis/batchDirectiveManage.api.js'
import { nameTrans } from '@/utils/index.js'
import CommandDetail from './CommandDetail.vue'
import { isBlank } from '@/utils/validate'

export default {
	name: 'directDetail',
	components: { CommandDetail },
	data() {
		return {
			columns: [
				{
					key: 'deviceNo',
					name: '设备编号',
				},
				{
					key: 'ts',
					name: '创建时间',
				},
				{
					key: 'status',
					name: '状态',
					render: (h, row) => {
						const dotArr = ['warning', 'success', 'error']
						return h('div', {}, [
							h('span', {
								class: 'dot-' + dotArr[+row.status],
							}),
							nameTrans(row.status, this.commandStatus),
						])
					},
				},
				{
					key: 'ops',
					name: '操作',
					render: (h, row) => {
						//  自定义表格内容展示
						return h(
							'el-button',
							{
								props: {
									type: 'text',
									size: 'medium',
								},
								on: {
									click: () => {
										this.commandDetailData.map(item => {
											if (Object.prototype.hasOwnProperty.call(row, item.key)) {
												if (item.key == 'detail') {
													item.value = row[item.key] || {}
												} else {
													item.value = row[item.key] || '--'
												}
											}
										})
										this.visible = true
									},
								},
							},
							'查看指令详情',
						)
					},
				},
			],
			tableData: [],
			pageParams: {
				hasNext: false,
				idOffset: '', //下一页offset
				currentIdOffset: '', //当前请求offset
			},
			visible: false,
			commandDetailData: [
				{
					key: 'deviceNo',
					label: '设备编号',
					value: '',
				},
				{
					key: 'detail',
					label: '指令内容',
					value: {},
				},
			],
			basicInfo: {},
			loading: false,
			params: {
				status: null,
			},
			queryParams: {},
		}
	},
	computed: {
		basicInfoData() {
			let obj = {
				title: '基本属性',
				row: 4,
				list: [
					{
						key: 'deviceTypeName',
						label: '设备类型',
						value: '--',
					},
					{
						key: 'userTypeName',
						label: '客户类型',
						value: '--',
						tenantType: '1',
					},
					{
						key: 'waterRegionName',
						label: '所属区域',
						value: '--',
						tenantType: '1',
					},
					{
						key: 'orgName',
						label: '所属机构',
						value: '--',
					},
					{
						key: 'cmdModelName',
						label: '指令名称',
						value: '--',
					},
					{
						key: 'createTime',
						label: '生成时间',
						value: '--',
					},
					{
						key: 'totalCount',
						label: '设备总数',
						value: '--',
					},
					{
						key: 'successCount',
						label: '成功数',
						value: '--',
					},
					{
						key: 'waitCount',
						label: '待执行数',
						value: '--',
					},
					{
						key: 'failCount',
						label: '失败数',
						value: '--',
					},
				],
			}
			obj.list = obj.list.filter(item => isBlank(item.tenantType) || item.tenantType === this.tenantType)
			obj.list.map(item => {
				if (!isBlank(this.basicInfo[item.key])) {
					item.value = this.basicInfo[item.key].toString()
				}
			})
			return obj
		},
		tenantType() {
			return this.$store.getters.userInfo.tenantType
		},
		taskNo() {
			return this.$route.query.taskNo
		},
		commandTaskId() {
			return this.$route.query.commandTaskId
		},
		// 指令状态
		commandStatus() {
			return this.$store.getters.dataList.commandStatus || []
		},
	},
	methods: {
		// 指令执行表具监控列表
		getCommandDetailList(currentIdOffset) {
			this.loading = true
			this.pageParams.currentIdOffset = currentIdOffset
			let params = Object.assign(
				{
					limit: 10,
					idOffset: this.pageParams.currentIdOffset,
				},
				{
					taskNo: this.taskNo,
					status: !isBlank(this.params.status) ? this.params.status : undefined,
				},
			)
			this.queryParams = JSON.parse(JSON.stringify(params))
			apiBatchCommandDetailList(params)
				.then(res => {
					this.pageParams.hasNext = res.hasNext
					this.pageParams.idOffset = res.idOffset
					this.tableData = res.records || []
				})
				.finally(() => {
					this.loading = false
				})
		},
		// 翻页
		currentPageChange(currentIdOffset) {
			this.getCommandDetailList(currentIdOffset)
		},
		//请求批量指令字段信息
		getBasicInfo(params) {
			apiGetBasicInfo(params).then(res => {
				this.basicInfo = res || {}
			})
		},
		reset() {
			this.params.status = null
			this.getCommandDetailList('')
			this.queryParams = {}
		},
		query() {
			this.getCommandDetailList('')
		},
		handleReSend() {
			this.$confirm('你确定要补发指令吗？').then(() => {
				this.loading = true
				apiReSend({
					taskNo: this.taskNo,
					status: this.queryParams.status,
					commandTaskId: this.basicInfo.commandTaskId,
				})
					.then(() => {
						this.$message.success('指令补发成功')
						this.query()
					})
					.finally(() => {
						this.loading = false
					})
			})
		},
	},
	activated() {
		this.params.status = null
		this.queryParams = {}
		this.getCommandDetailList('')
		this.getBasicInfo({
			commandTaskId: this.commandTaskId,
		})
	},
}
</script>
<style lang="scss" scoped>
@import '../runLayout.scss';
.directive-detail-main {
	flex-direction: column;
	padding: 0 24px;
	.basic-info {
		border-bottom: 0.5px dashed #999999;
	}
	.directive-detail-table-show {
		flex: 1;
		padding-top: 30px;
		display: flex;
		flex-direction: column;
		height: 0;
		.table-header {
			font-size: 16px;
			font-weight: 600;
			color: #333333;
			padding-bottom: 20px;
			display: flex;
			justify-content: space-between;
		}
		.table-box {
			flex: 1;
			height: 0;
			display: flex;
			.search-box {
				border-right: 1px dashed #ccc;
				box-sizing: border-box;
				width: 300px;
				height: 100%;
				padding: 16px 24px 16px 0;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				margin-right: 16px;
				.search-items {
					.search {
						width: 100% !important;
					}
				}
				.search-btns {
					display: flex;
					justify-content: center;
					.el-button {
						width: 40%;
						& + .el-button {
							margin-left: 20px;
						}
					}
				}
			}

			.table-content {
				flex: 1;
				width: 0;
			}
		}
	}
}
::v-deep .desc {
	.el-textarea.is-disabled .el-textarea__inner {
		border: 1px solid #d9d9d9;
		background: #ffffff !important;
		color: #4e4e4e;
	}
}
</style>
